import React, {useState, useEffect, useRef} from "react";
import {Form} from "react-bootstrap";
import { ExoLabel, apiCalls } from "helpers";
import AsyncSelect from 'react-select/async';
import {FormattedMessage} from "react-intl";

/*
Options
onChange() : callback function
list : nom de la liste (call ajax api - dropdown.js
value : item sélectionné.
translate BOOLEAN : Traduit les labels si TRUE
 */

const Autocomplete = ({
                      onChange, list, name, value,
                      disabled = false, className='', label = false,
                      formikProps, autoFocus = false, errorClass, data, hideLabel, activeOnly = true
}) =>  {

    const [inputValue, setInputValue] = useState(value);
    const ddlRef = useRef(null);

    useEffect(() => {
        if(value){
            setInputValue({
                key: value,
                id: value,
                label: value
            });
            if(data?.label){
                loadOptions(data.label, (e)=>{
                    setInputValue(e)
                }).then();
            }
        }else{
            setInputValue(0)
        }
        if(autoFocus){
            ddlRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if(formikProps){
            if(formikProps.values.item_id === ''){
                setInputValue(0)
                if(autoFocus){
                    ddlRef.current.focus();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikProps])

    useEffect(() => {
        if(!disabled && value === ''){
            setInputValue(0);
            if(autoFocus){
                ddlRef.current.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled])

    const handleChange = (e) => {
        onChange(name, e.key, e.value, e.label, e);
        setInputValue(e);
    }

    const clearField = (name) => {
        handleChange(name, null, '')
    }

    const loadOptions = async (inputValue, callback) => {
        if(inputValue.length > 1){
            let data = {
                input: inputValue
            }
            if(activeOnly){
                data.active = true;
            }
            await apiCalls.post('/commons/autocomplete/'+list, data)
                .then( (resp) => {
                    callback(resp.data);
                })
                .catch( err => { console.log(err) });
        }

    }

    return (
        <Form.Group controlId={`form${name}`} className={"has-float-label " + errorClass}>
            {!hideLabel ? <Form.Label>
                <ExoLabel id={label ? label : `form.${name}`}/>
                {inputValue ?
                    <span className="ms-2 small text-info pointer" onClick={() => clearField(name)}>
                        [ <i className="fa-solid fa-times me-1"/><ExoLabel id={"form.clearField"}/> ]
                    </span> : '' }
            </Form.Label> : ''}
            <AsyncSelect
                ref={ddlRef}
                placeholder={<FormattedMessage id={"form.typeToSearch"}/>}
                isDisabled={disabled}
                className={"react-select " + (className ? className : " mt-3 ") +
                    (formikProps ?
                            formikProps.touched[name] && formikProps.errors[name] ? " error" : ''
                            : ''
                    )
                }
                classNamePrefix="react-select"
                cacheOptions
                name={name}
                value={inputValue}
                loadOptions={loadOptions}
                onChange={handleChange}
            />
            {formikProps ? formikProps.touched[name] && formikProps.errors[name] ? (
                <div className="error-message">{formikProps.errors[name]}</div>
            ): null : null }
        </Form.Group>
    )

}
export default Autocomplete;